import React, { useCallback, useEffect, useState } from "react";
import { css as emotionCss, CSSInterpolation } from "@emotion/css";
import {
  CardExpiryElement,
  CardCvcElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardNumberElementChangeEvent,
  StripeElementChangeEvent,
} from "@stripe/stripe-js";
import numeral from "numeral";
import isNil from "lodash/isNil";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { createCss } from "./styles";
import { useStripeContext } from "@components/PaymentForm/StripeProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useUserStore } from "@store/UserStore";
import { PAYMENTS_API } from "@services/api";

import MastercardIcon from "@images/mastercard.svg";
import DiscoverIcon from "@images/discover.svg";
import VisaIcon from "@images/visa.svg";
import AmexIcon from "@images/amex.svg";

const getBrandImage = (brand?: string) => {
  switch (brand) {
    case "visa":
      return VisaIcon;
    case "amex":
      return AmexIcon;
    case "mastercard":
      return MastercardIcon;
    case "discover":
      return DiscoverIcon;
    default:
      return undefined;
  }
};

const elementStyles = {
  base: {
    color: "#32325D",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    fontWeight: "500",

    "::placeholder": {
      color: "#cfd7df",
    },

    ":-webkit-autofill": {
      color: "#cfd7df",
    },
  },
  invalid: {
    color: "#32325D",

    "::placeholder": {
      color: "#cfd7df",
    },
  },
};

const elementClasses = {
  empty: "empty",
  focus: "focused",
  invalid: "invalid",
};

const PaymentForm: React.FC = () => {
  const [{ user, paymentSource }, { setPaymentSource }] = useUserStore();
  const [inited, setInited] = useState(false);
  const [brand, setBrand] =
    useState<StripeCardNumberElementChangeEvent["brand"]>("unknown");
  const [error, setError] = useState("");
  const { setMode, mode, elements, ...restContext } = useStripeContext();
  const css = createCss();

  const initFetch = useCallback(async () => {
    if (!user) {
      setMode("edit");
      setInited(true);
    } else {
      try {
        const { data } = await PAYMENTS_API.paymentsByIdGet();

        setPaymentSource(data);
        if (!data.id) {
          setMode("edit");
        }
      } finally {
        setInited(true);
      }
    }
  }, [setInited]);

  useEffect(() => {
    void initFetch();
  }, [initFetch]);

  useEffect(() => {
    setBrand("unknown");
    setError("");
  }, [mode]);

  if (!inited) {
    return (
      <div css={css.root}>
        <div css={css.placeholder}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  const handleChange = (
    event: StripeElementChangeEvent & {
      brand?: StripeCardNumberElementChangeEvent["brand"];
    },
  ) => {
    if (event.error) {
      setError(event.error.message);

      return;
    }

    if (event.brand) {
      setBrand(event.brand);
    }

    if (event.complete) {
      if (event.elementType === "cardNumber") {
        elements?.getElement(CardExpiryElement)?.focus();
      }

      if (event.elementType === "cardExpiry") {
        elements?.getElement(CardCvcElement)?.focus();
      }
    }

    setError("");
  };

  return (
    <div css={css.root} data-test="payment-form">
      {!!paymentSource?.id && (
        <FormControl>
          <RadioGroup
            name="mode"
            value={mode}
            onChange={(event) => setMode(event.target.value as typeof mode)}
          >
            <FormControlLabel
              value="view"
              control={<Radio color="primary" />}
              label={
                <div css={css.existingCard}>
                  {!isNil(getBrandImage(paymentSource?.brand)) ? (
                    <img
                      src={getBrandImage(paymentSource?.brand)}
                      alt={paymentSource?.brand}
                      css={css.existingCardImage}
                    />
                  ) : (
                    paymentSource?.brand
                  )}
                  <div css={css.existingSpacing} />
                  xxxx {paymentSource.last4}
                  <div css={css.existingSpacing} />
                  {numeral(paymentSource.expMonth).format("00")}/
                  {paymentSource.expYear}
                </div>
              }
              classes={{
                root: emotionCss(css.materialLabelRoot as CSSInterpolation),
              }}
            />
            <FormControlLabel
              value="edit"
              control={<Radio color="primary" />}
              label="Edit payment method"
              classes={{
                root: emotionCss(css.materialLabelRoot as CSSInterpolation),
              }}
              data-test="edit-radio"
            />
          </RadioGroup>
        </FormControl>
      )}
      {mode === "edit" && (
        <div css={css.stripeForm}>
          <div css={css.row}>
            <div css={css.field}>
              <CardNumberElement
                id="stripe-card-number"
                onChange={handleChange}
                options={{
                  classes: elementClasses,
                  style: elementStyles,
                }}
                css={css.stripeElement}
              />
              <label htmlFor="stripe-card-number" css={css.label}>
                Card number
              </label>
              <div css={css.baseline} />
              <div css={css.cardIcon}>
                {!isNil(getBrandImage(brand)) && (
                  <img src={getBrandImage(brand)} alt={brand} />
                )}
              </div>
            </div>
          </div>
          <div css={css.row}>
            <div css={css.field} className="half-width">
              <CardExpiryElement
                id="stripe-card-expiry"
                onChange={handleChange}
                options={{
                  classes: elementClasses,
                  style: elementStyles,
                }}
                css={css.stripeElement}
              />
              <label htmlFor="stripe-card-expiry" css={css.label}>
                Expiration
              </label>
              <div css={css.baseline} />
            </div>
            <div css={css.field} className="half-width">
              <CardCvcElement
                id="stripe-card-cvc"
                onChange={handleChange}
                options={{
                  classes: elementClasses,
                  style: elementStyles,
                }}
                css={css.stripeElement}
              />
              <label htmlFor="stripe-card-cvc" css={css.label}>
                CVC
              </label>
              <div css={css.baseline} />
            </div>
          </div>
          <div css={css.error}>{error || restContext.error}</div>
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
