import React, { useEffect } from "react";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import { globalConfig } from "@constants";
import { PAYMENTS_API } from "@services/api";
import { useUserStore } from "@store/UserStore";
import { useNotificationStore } from "@store/NotificationStore";

type StripeFormState = {
  mode: "edit" | "view";
  error: string | null;
  fetching: boolean;
};

type StripeState = StripeFormState & {
  elements: StripeElements | null;
  stripe: Stripe | null;
  setMode: (mode: "edit" | "view") => void;
  createPaymenMethod: (
    options?: CreatePaymentOptions,
  ) =>
    | Promise<
        { payment_method: string; last4?: string | null } | boolean | undefined
      >
    | boolean
    | undefined;
};

type CreatePaymentOptions = Partial<{
  saveSource: boolean;
}>;

const defaultState: StripeFormState = {
  error: null,
  fetching: false,
  mode: "view",
};

const StripeContext = React.createContext<StripeState>({
  ...defaultState,
  createPaymenMethod: () => undefined,
  elements: null,
  setMode: () => undefined,
  stripe: null,
});

export const StripeProvider: React.FC = ({ children }) => {
  const [stripe] = React.useState(() =>
    loadStripe(globalConfig.STRIPE_PUBLISHABLE_KEY),
  );

  return (
    <Elements stripe={stripe}>
      <StripeProviderInner>{children}</StripeProviderInner>
    </Elements>
  );
};

const StripeProviderInner: React.FC = ({ children }) => {
  const elements = useElements();
  const stripe = useStripe();
  const [{ paymentSource }, { savePaymentSource }] = useUserStore();
  const [, { setUnknownErrorNotification }] = useNotificationStore();
  const [state, setState] = React.useState(defaultState);

  useEffect(() => {
    setState((prev) => ({ ...prev, error: null }));
  }, [state.mode]);

  const createPaymenMethod = async (
    options: CreatePaymentOptions = {
      saveSource: true,
    },
  ) => {
    if (!stripe || !elements) {
      throw new Error("Stripe not defined");
    }

    if (state.mode !== "edit") {
      throw new Error("Invalid form mode");
    }

    setState((prev) => ({ ...prev, error: null }));

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      throw new Error("Card Element not found");
    }

    try {
      setState((prev) => ({ ...prev, fetching: true }));

      const { data } = await PAYMENTS_API.paymentsByIdSetup();

      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          card: cardElement,
          type: "card",
        });

      if (paymentMethodError) {
        throw paymentMethodError;
      }

      const { setupIntent, error } = await stripe.confirmCardSetup(
        data.clientSecret,
        {
          payment_method: paymentMethod?.id,
        },
      );

      if (error) {
        setState((prev) => ({ ...prev, error: error.message || null }));
        return false;
      }

      if (!setupIntent) {
        throw new Error("SetupIntent not defined");
      }

      const { payment_method } = setupIntent;

      if (payment_method) {
        if (!options.saveSource) {
          return { payment_method, last4: paymentMethod?.card?.last4 };
        }

        if (!paymentSource) {
          throw new Error("User payment source not defined");
        }

        try {
          await savePaymentSource({
            ...paymentSource,
            id: payment_method,
          });
          setState((prev) => ({ ...prev, mode: "view" }));
          return true;
        } catch (error) {
          const errors = error?.response?.data?.errors;

          if (errors) {
            setState((prev) => ({ ...prev, error: errors[0].title }));
          } else {
            setUnknownErrorNotification();
          }
          return false;
        }
      }
    } finally {
      setState((prev) => ({ ...prev, fetching: false }));
    }
  };

  return (
    <StripeContext.Provider
      value={{
        ...state,
        createPaymenMethod,
        elements,
        setMode: (mode) => {
          setState((prev) => ({ ...prev, mode }));
        },
        stripe,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export const useStripeContext = (): StripeState => {
  const state = React.useContext(StripeContext);

  if (!state) {
    throw new Error("Stripe state not found");
  }

  return state;
};
