import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    padding: "10px 0",
  },

  placeholder: {
    alignItems: "center",
    display: "flex",
    height: 60,
    justifyContent: "center",
  },

  stripeForm: {
    paddingBottom: 20,
  },

  materialLabelRoot: {
    "& > span:first-of-type": {
      padding: "6px 12px",
    },
  },

  stripeElement: (theme) => ({
    backgroundColor: "transparent",
    bottom: 0,
    left: 0,
    opacity: 0,
    paddingBottom: 7,
    position: "absolute",
    transition: "opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)",
    width: "100%",
    willChange: "opacity",

    "&.focused": {
      "& + label": {
        color: theme.palette.primary.main,
      },

      "& + label + $baseline": {
        backgroundColor: theme.palette.primary.main,
      },
    },

    "&.invalid": {
      "& + label": {
        color: theme.palette.error.main,
      },

      "& + label + $baseline": {
        backgroundColor: theme.palette.error.main,
      },
    },

    "&.focused, &:not(.empty)": {
      opacity: 1,
    },

    "&.focused + label, &:not(.empty) + label": {
      cursor: "default",
      transform: "scale(0.85) translateY(-25px)",
    },
  }),

  label: {
    bottom: 8,
    color: "#cfd7df",
    cursor: "text",
    left: 0,
    overflow: "hidden",
    position: "absolute",
    textOverflow: "ellipsis",
    transformOrigin: "0 50%",
    transitionDuration: "0.3s",
    transitionProperty: "color, transform",
    transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1)",
    whiteSpace: "nowrap",
    width: "100%",
  },

  cardIcon: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    width: 50,

    "& img": {
      width: "100%",
    },
  },

  field: {
    fontSize: 16,
    fontWeight: 500,
    height: 50,
    margin: "0 10px",
    position: "relative",
    width: "100%",

    "&.half-width": {
      width: "50%",
    },
  },

  baseline: {
    backgroundColor: "#cfd7df",
    bottom: 0,
    height: 1,
    left: 0,
    position: "absolute",
    transition: "background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)",
    width: "100%",
  },

  row: {
    display: "flex",
    margin: "0 -10px 10px",
  },

  error: (theme) => ({
    color: theme.palette.error.main,
    fontSize: 14,
    lineHeight: "16px",
    marginTop: 10,
    minHeight: 16,
  }),

  existingCard: {
    display: "flex",
  },

  existingCardImage: {
    width: 40,
  },

  existingSpacing: {
    width: 30,
  },
});
